$(function() {
    var dirRtlFlag = false;
    if ($("html").attr('dir') == 'rtl') {
        dirRtlFlag = true;
    }

    $('.homepage-slider').slick({
        dots: true,
        infinite: true,
        speed: 1000,
        rtl: dirRtlFlag,
        arrows: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false,
        nextArrow: '#homepage-slider-left-arrow',
        prevArrow: '#homepage-slider-right-arrow'
    });

    $(window).scroll(function(){
      var x = $(this).scrollTop(); /* scroll pos */
      var y = $("html").height();
      var z = x / y;

      curTop = (((z*800)+0));
      $("#banners-scroll").css("top", (curTop+0)+"px");
    });

    var hasWebRTC = navigator.getUserMedia ||
                    navigator.webkitGetUserMedia ||
                    navigator.mozGetUserMedia ||
                    navigator.msGetUserMedia;

    if (!hasWebRTC) {
      $('.wrap-banner-scroll').css('position','static');
    }




});
