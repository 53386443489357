$(function() {
  $( "select[name*='db[5249]']" ).change(function() {
    // alert( $(this).val() );
    //ammount ticket
    amount = parseInt($(this).val());
    
    // price for 1 ticket
    priceinput = $( "input[name*='db[5251]']" ).val();
    if (  isNaN(priceinput)) {
      priceinput = 1;
    }
    price = parseInt(priceinput);
   
    total = amount * price;
    console.log(price);  
    $( "input[name*='db[5246]']" ).val(' מחיר :  '+total + ' ₪' );
    $( "input[name*='db[5244]']" ).val(total); 
     

  });

  $("input[type='text'][name='db[5246]']")
    .data('defaultVal', $( "input[name*='db[5246]']" ).val())
    .val(function() { return $.data(this, 'defaultVal') })
    .keypress(function() {
        return false;  
    })
    .blur(function() {
        if(this.value !== $.data(this, 'defaultVal'))
            this.value = $.data(this, 'defaultVal'); 
    });

      
});