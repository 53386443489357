$(function() {
  var dirRtlFlag = false;
    if ($("html").attr('dir') == 'rtl') {
        dirRtlFlag = true;
    }
  $('.slick-slider').slick({
    dots: true,
    infinite: true,
    speed: 300,
    arrows: false,
    rtl: dirRtlFlag,
    fade: true,
    autoplay: true,
    slidesToShow: 1
  });

});
