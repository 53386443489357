$(function() {
    var dirRtlFlag = false;
    if ($("html").attr('dir') == 'rtl') {
        dirRtlFlag = true;
    }

    $('.langing-slider').slick({
        dots: true,
        infinite: true,
        speed: 1000,
        rtl: dirRtlFlag,
        arrows: true,
        fade: true,
        autoplay: true,
        autoplaySpeed: 5000,
        pauseOnHover: false
    });


});