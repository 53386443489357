$(function(){

  $(window).on("scroll", scrollFunction);

  $('.aside-panel-tab').on('click', function(){
    var asidePanel = $(this).closest('.wrapper-all-aside-panel');
    if ($(asidePanel).hasClass('aside-panel__open')) {
      $(asidePanel).removeClass('aside-panel__open');
    } else {
      $(asidePanel).addClass('aside-panel__open');
    }
  });
  function scrollFunction() {
    if ($(window).scrollTop() > 220) {
      $(".wrapper-all-aside-panel").addClass("scrolling");
      $(".wrapper-all-aside-panel").removeClass("aside-panel__open");
    } else {
      //$(".desktop-menu").removeClass("scrolling desktop-menu_open");
    }
  }




$(".pop-up-btn").on("click", function(){




  $('#myModal').modal();


  // $('#myModal').on('shown.bs.modal',function(){      //correct here use 'shown.bs.modal' event which comes in bootstrap3
  //   iFrameResize({log:true,bodyPadding:'10px'}, '#myIframe');
  // });




});

});
