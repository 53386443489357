$(function() {
   
  // Side Menu Control
  var myDir = ($("html").attr('dir') == 'rtl' ? 'right' : 'left');
  var mySlidebars = new $.slidebars();
  $('.mobile-menu-btn').on('click', function() {
    mySlidebars.slidebars.toggle(myDir);
  }); 


$(document).scroll(function(){

  if($(window).scrollTop() > 50){
    $('body').addClass('scrolling');
  } else {
    $('body').removeClass('scrolling');
  }

});
});