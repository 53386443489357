$(function() {
    var dirRtlFlag = false;
    if ($("html").attr('dir') == 'rtl') {
        dirRtlFlag = true;
    }




  $('.homepage-content__slider').slick({
      dots: true,
      dotsClass: 'homepage-content__dots',
      infinite: true,
      speed: 1000,
      rtl: dirRtlFlag,
      arrows: false,
      fade: true,
      autoplay: true,
      autoplaySpeed: 5000,
      pauseOnHover: false,
      responsive: [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      dots: false
    }
  }
]
  });
});
