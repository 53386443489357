// (function( $ ){

//   $("body").on("click",".home-events .wrap-calendar-header-navi_style-btn",function(e){
//     var el = $(this);
//     var month = el.data("month");
//     var year = el.data("year");
//     $.get( "index.php?dir=site&page=calendar&op=ajax&month="+month+"&year="+year , {async:false})
// 	   .done(function( data ) {
//        console.log(data);
// 	      $(".header-calendar").html(data);
// 	  });
//   });

//   $("body").on("click",".big-calendar-wrap .wrap-calendar-header-navi_style-btn",function(e){
//     var el = $(this);
//     var month = el.data("month");
//     var year = el.data("year");
//     $.get( "index.php?dir=site&page=events&op=calendar&month="+month+"&year="+year , {async:false})
// 	   .done(function( data ) {
// 	      $(".big-calendar-wrap").html($(data).find(".big-calendar-wrap"));
// 	  });
//   });

//   $("body").on("click",'.day-events',function(){
//     var cs = $(this).data('event');
//     $('.home-events_selected').removeClass('home-events_selected');
//     $('.home-events__absolute-container[data-event=' + cs + ']').addClass('home-events_selected');
//   });

//   // $('body').on('mouseenter','.day-events',function(){
//   //   $(this).find('.home-events__events').fadeIn(100);
//   // })
//   // $('body').on('mouseleave','.day-events',function(){
//   //   $(this).find('.home-events__events').fadeOut(100);
//   // })
// })( jQuery );

(function( $ ){
  // $('body').on({
  //   mouseenter: function() {
  //     var el = $(this).children(".calendar-popap");
  //     var offsetLeft = el.offset().left;
  //     var width = el.width();
  //     var windowWidth = $(window).width() - 30;
  //     if(offsetLeft + width > windowWidth){
  //       el.removeClass("calendar-popap--default-position");
  //       el.addClass("calendar-popap--top-position");
  //     }
  //   },
  //   mouseleave: function() {
  //   }
  // }, '.calendar-body .day-events');

  $("body").on("click",".wrap-calendar-header-navi_style-btn",function(){
    var el = $(this);
    var month = el.data("month");
    var year = el.data("year");
    $.get( "index.php?dir=site&page=calendar&op=ajax&month="+month+"&year="+year )
	   .done(function( data ) {
	      $(".wrapper-calendar").html(data);
	  }); 
  });
})( jQuery );
