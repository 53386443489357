
$(document).ready(function(){

  var id =getAnchor();
  
  if(id){
    $('html,body').animate({scrollTop: $("#"+id).offset().top - 25},'slow');
    console.log( id);
  }
  function getAnchor() {
    var currentUrl = document.URL,
	  urlParts   = currentUrl.split('#');
		
    return (urlParts.length > 1) ? urlParts[1] : null;
  }


 
  });