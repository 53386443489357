$(function() {

	$(".hasSub > a").click(function(e) {

		e.preventDefault();

		//$(".sb-left .sb-menu li.hasSub, .sb-right .sb-menu li.hasSub").removeClass("hasSub-open");

		$(this).parent("li").toggleClass("hasSub-open");

		return false;

	});

});

