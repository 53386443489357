 
$(function(){
    $.fn.removeClassRegex = function(regex) {
        return $(this).removeClass(function(index, classes) {
            return classes.split(/\s+/).filter(function(c) {
                return regex.test(c);
            }).join(' ');
        });
    };
});

var accessibilityFonts = {
  /*
  *
  */
  Constants:{
    MAX_STEP: 6
  },

  /*
  *
  */
  Globals:{
    currentStep: 0,
    bodyFontSize: 0,
    init:0
  },

  /*
  *
  */
  init: function() {
    if(!this.Globals.init){
      this.Globals.init = 1;
    }else{
      return false;
    }
    this.Globals.bodyFontSize = document.body.style.fontSize;
    if(!this.Globals.bodyFontSize){
      this.Globals.bodyFontSize = getComputedStyle(document.body, null).getPropertyValue('font-size');
    }
    document.body.style.fontSize = this.Globals.bodyFontSize;
    //TODO-if there is no font on body, set to 16
    this.changeFontSizeToEm(document.body,this.Globals.bodyFontSize);
  },

  /*
  *
  */
  changeFontSizeToEm: function(node, parentFontSize){
    if(!node){
      return ;
    }

    for( var i = 0; i < node.childNodes.length; i++){
      if(node.childNodes[i].nodeType == 1 && node.id != "DONTENTER"){
        var currentFontSize = node.childNodes[i].style.fontSize;
        var currentHeight = node.childNodes[i].style.height;

        if(!currentFontSize){
          currentFontSize = getComputedStyle(node.childNodes[i], null).getPropertyValue('font-size');
        }

        if(!currentHeight){
          currentHeight = getComputedStyle(node.childNodes[i], null).getPropertyValue('height');
        }
        node.childNodes[i].style.setProperty ("font-size", parseInt(currentFontSize) / parseInt(parentFontSize) + "em", "important");
      }
      if(node.childNodes[i].childNodes.length > 0 && node.id != "DONTENTER"){
        this.changeFontSizeToEm(node.childNodes[i],parseInt(currentFontSize));
      }
    }
  },

  /*
  *
  */
  resizeUp: function() {
    if(this.Globals.currentStep <= this.Constants.MAX_STEP){
      this.Globals.currentStep++;
      this.doStep();
    }
  },

  /*
  *
  */
  resizeDown: function(){
    if(this.Globals.currentStep > 0){
      this.Globals.currentStep--;
      this.doStep();
    }
  },
  /*
  *
  */
  resizeTo: function(step){

    if(step < this.Constants.MAX_STEP && step >= 0){
      this.Globals.currentStep = step;
      this.doStep();

      $('#fontsize_0').removeClass('selected');
      $('#fontsize_2').removeClass('selected');
      $('#fontsize_4').removeClass('selected');

      $('#fontsize_'+step).addClass('selected');
      $.cookie('fontsize', step);
    }
  },
  /*
  *
  */
  doStep: function(){

    var new_zise = parseInt(this.Globals.bodyFontSize) + parseInt(1 * this.Globals.currentStep);


    $('body')
        .removeClassRegex(/font_size_\d*/)
        .addClass('font_size_'+new_zise)
        .toggleClass('font_size_normal', 0 === parseInt(1 * this.Globals.currentStep) )
        .toggleClass('font_size_acc', 0 !== parseInt(1 * this.Globals.currentStep) );

    document.body.style.fontSize = new_zise + "px";
  },
  /*
  *
  */
  destory: function(){
    //needed?
  }
}

var accessibilityColors = {

    Vars: {
        originalBodyStyle: "", //not used
        bodyOrgClass: "",
        init: 0,
        currectColor: ""
    },

    init: function () {
        if (!this.Vars.init) {
            this.Vars.init = 1;
        } else {
            return false;
        }

        this.Vars.bodyOrgClass = "";
        if (document.body.className) {
            this.Vars.bodyOrgClass = document.body.className;
        }
    },

    ColorBlinded: function () {

        $('body').removeClass('heaveysightedblack');
        $('body').removeClass('heaveysightedwhite');
        $('body').addClass('blackandwhite');
        $('body').addClass('with_js');
        $('#HeaveySightedBlackBtn').removeClass('selected');
        $('#HeaveySightedWhiteBtn').removeClass('selected');
        $('#ColorBlindedBtn').addClass('selected');


        $.removeCookie('color');
        $.cookie('color', 'blackandwhite');
    },
    /*
     *
     */
    HeaveySightedBlack: function () {

        $('body').removeClass('blackandwhite');
        $('body').removeClass('heaveysightedwhite');
        $('body').addClass('heaveysightedblack');
        $('body').addClass('with_js');
        $('#HeaveySightedBlackBtn').addClass('selected');
        $('#HeaveySightedWhiteBtn').removeClass('selected');
        $('#ColorBlindedBtn').removeClass('selected');


        $.removeCookie('color');
        $.cookie('color', 'heaveysightedblack');
    },
    /*
     *
     */
    HeaveySightedWhite: function () {

        $('body').removeClass('blackandwhite');
        $('body').removeClass('heaveysightedblack');
        $('body').addClass('heaveysightedwhite');
        $('body').addClass('with_js');

        $('#HeaveySightedBlackBtn').removeClass('selected');
        $('#HeaveySightedWhiteBtn').addClass('selected');
        $('#ColorBlindedBtn').removeClass('selected');

        $.removeCookie('color');
        $.cookie('color', 'heaveysightedwhite');

    },
    /*
     *
     */
    Reset: function (color) {
        return (this.Vars.currectColor == color);
    }

}




$('#quickMenu').on('focusin', function(){
    $(this).addClass('focusin');
}).on('focusout', function(){
    $(this).removeClass('focusin');
});

var pagedir = $("html").attr('dir');

var dir_left = (pagedir === 'ltr' ? 'left' : 'right');
var dir_right = (pagedir === 'ltr' ? 'right' : 'left');

$('body').addClass('with_js');
$('.accessibility_wrapper.desktop').css(dir_left, '5px');
$('.accessibility_wrapper.desktop').css(dir_right, 'auto');

$('#quickMenu').css(dir_left, '285px');
$('#quickMenu').css(dir_right, 'auto');

$('.accessibilityLinks').css(dir_left, '-240px');
$('.accessibilityLinks').css(dir_right, 'auto');
$('.accessibilityLinks').hide();

$('#accessibilityLink').on('click', function(){
    toggleNMenuLinks();
});



$('body').on('keypress', '.shortcut_menu_links [tabindex="1"]', function(e){
    var keycode = (e.keyCode ? e.keyCode : e.which);

    if (keycode === 13 || keycode === 32 ) {
        e.preventDefault();
        $(e.target).trigger('click');
    }
});

$('body').on('keypress', 'a', function(e){
    var keycode = (e.keyCode ? e.keyCode : e.which);

    if (keycode === 13 || keycode === 32 ) {
        $(e.target).trigger('click');
    }
});

$('body').on('keypress', '#alert_wrap > div .close, .nav .back, .nav .next', function(e){
    var keycode = (e.keyCode ? e.keyCode : e.which);

    if (keycode === 13 ) {
        e.preventDefault();
        $(e.target).trigger('click');
    }
});

$('body').on('keypress', '.ftr-check-uncheck', function(e){
    var keycode = (e.keyCode ? e.keyCode : e.which);

    if (keycode === 13 || keycode === 32 ) {
        e.preventDefault();
        $(e.target).trigger('click');
    }
});


$('body').on('keypress', '#filter_form', function(e){
    var keycode = (e.keyCode ? e.keyCode : e.which);

    $('.select_warp').each(function(){
      $(this).children('select').attr('onchange' , '');
    });


    if (keycode === 13 || keycode === 32 ) {
        $('#filter_form').submit();
    }
});


$(document).keyup(function(e) {

     if (e.keyCode == 27) {
       $('#alert_warp').fadeOut();
    }
});

$('body').on('keypress' , '.main-navi ul  li' , function(e){

    var keycode = (e.keyCode ? e.keyCode : e.which);
    //alert(keycode);
    if (keycode == 9  ) {

        $(this).siblings('li').children('ul').fadeOut();

        $(this).children('ul').fadeIn();
    }
});


function toggleNMenuLinks() {
    var properties = {};

    if ($('body').hasClass('accessibility')) {
        $('body').removeClass('accessibility');

        properties[dir_left] = '-240px';
        $('.accessibilityLinks').animate(properties, 500, function () {
            $(this).hide();
        });
    } else {
        $('body').addClass('accessibility');
        properties[dir_left] = '0';
        $('.accessibilityLinks').show().animate(properties, 500);

        accessibilityColors.init();
        accessibilityFonts.init();
    }
}

function openNMenuLinks() {
    var properties = {};

      $('body').addClass('accessibility');
      properties[dir_left] = '0';
      $('.accessibilityLinks').show().animate(properties, 500);

      accessibilityColors.init();
      accessibilityFonts.init();

}

function turnOffAccessibility() {
    $.removeCookie('color');
    $.removeCookie('flicker');
    $.removeCookie('fontsize')
    location.reload();
}


function goToSelector(e , p , i) {

    var s = $(e).offset().top - 200;

    $('body').addClass('accessibility');
    var ti = $(e).attr('tabindex');


    $(p).find(i).each(function () {
         $(this).attr('tabindex', '1');
    });

    if (typeof (ti) === 'undefined') {
        $(e).attr('tabindex', '1');

        $(e).find("a").attr('tabindex', '1');
    }

    $(".acc_focus").removeClass('acc_focus');

    $(e).focus().addClass('acc_focus');


    $('html, body').animate({
        scrollTop: s
    }, 2000);
}

function Warp_Flickerblocks(){

    var flag = $('body').hasClass('Flickerblocks');

    $('body').toggleClass('Flickerblocks', !flag);

    if (typeof window.Flickerblocks === 'function') {
        window.Flickerblocks(Flickerblocks);
    }

}

window.Flickerblocks = function(){
   //var flag2 = $("#news_scroller").hasClass("stop");
   //$('#news_scroller').toggleClass('stop', !flag2);

    if($('body').hasClass('Flickerblocks')){

      $('#hp-banners').slick('slickPause');

      $('#flickerBtn').addClass('selected');
      $('.rotateBanner').addClass('stop');
      // newsmarqueeSlider.stopAuto();
      // homepageslider.stopAuto();
      // marquee.stop ();
      $.cookie('flicker', 'block');
      $('#flickerBtn').addClass('selected');

    }else{

      $('#flickerBtn').removeClass('selected');
      $('.rotateBanner').removeClass('stop');
      // newsmarqueeSlider.startAuto();
      // homepageslider.startAuto();
      // marquee.start ();
      $.cookie('flicker', 'unblock');
      $('#flickerBtn').removeClass('selected');

    }



};



$('.custom-cols').on('focusin focusout', function(e){
    $(this).toggleClass('focusin', 'focusin' === e.type);
});

$('.header_menu .dropdown-menu').parent().on('focusin focusout', function (e) {
    var self = this;
    setTimeout(function(){
        if( e.type === 'focusout' && $(self).find('*:focus').length === 0){
            $(self).removeClass('open').children('.dropdown-menu').stop(true, true).delay(400).fadeOut();
        }else{
            $(self).addClass('open').children('.dropdown-menu').stop(true, true).delay(200).fadeIn();
        }
    }, 10);
});



$(document).ready(function(){

  if($.cookie('color') == 'blackandwhite'){
      accessibilityColors.ColorBlinded();
      $('#HeaveySightedBlackBtn').removeClass('selected');
      $('#HeaveySightedWhiteBtn').removeClass('selected');
      $('#ColorBlindedBtn').addClass('selected');

  }else if( $.cookie('color') == 'heaveysightedblack'){
      accessibilityColors.HeaveySightedBlack();
      $('#HeaveySightedBlackBtn').addClass('selected');
      $('#HeaveySightedWhiteBtn').removeClass('selected');
      $('#ColorBlindedBtn').removeClass('selected');

  }else if( $.cookie('color') == 'heaveysightedwhite'){
      accessibilityColors.HeaveySightedWhite();
      $('#HeaveySightedBlackBtn').removeClass('selected');
      $('#HeaveySightedWhiteBtn').addClass('selected');
      $('#ColorBlindedBtn').removeClass('selected');
  }


  if($.cookie('flicker') == 'block'){
        Warp_Flickerblocks();
  }

  if($.cookie('fontsize') == '0' || $.cookie('fontsize') == '2' || $.cookie('fontsize') == '4'){
      accessibilityFonts.init();
      accessibilityFonts.resizeTo($.cookie('fontsize'));
      $('#fontsize_'+$.cookie('fontsize')).addClass('selected');
  }


});
